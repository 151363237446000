.single-product {
    font-family: "Mundial" !important;
    .variations {
        margin-bottom: 30px !important;
    }
    .woocommerce-variation-availability {
        display: none !important;
    }
    .variations_form {
        .reset_variations {
            display: none !important;
        }
        .label {
            font-size: 12px;    
            label {
                font-weight: 400 !important;
            }
            .woo-selected-variation-item-name {
                display: none !important;
            }
        }
        .variable-items-wrapper {
            .variable-item {
                width: 51px !important;
                
                height: 51px !important;
                border: 1px solid #FFC035 !important;
                box-shadow: none !important;
                font-size: 14px !important;
                font-weight: normal !important;
                background: none !important;
                color: #FFC035;

                &.selected, &:hover {
                    background: #FFC035 !important;
                    color: white !important;
                }
            }
        }
    }
    .product-tabs {
        margin: 18px 0px;
        &__item {
            border-bottom: 1px solid #4F4E4C;
            .arrow {
                transition: all .45s ease;
            }
            &__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                padding: 20px 0px;
                line-height: 1;
                span {
                    line-height: 1;
                }
                &.active {
                    .arrow {
                        transform: rotate(180deg);
                    }
                }
            }
            &__body {
                margin: 4px 0px 24px 0px;
                font-size: 14px;
                display: none;
                ul {
                    padding-left: 14px;
                }
                &.list-visible {
                    display: block;
                }
            }
        }
    }
    .woocommerce-product-gallery__wrapper {
        width: 100%;
    }
    .onsale, .quantity, .posted_in, .tabs, .woocommerce-Tabs-panel {
        display: none !important;
    }

    .woocommerce-product-details__short-description, .woocommerce-product-details__main-description {
        font-size: 14px !important;
    }
    .single_add_to_cart_button {
        font-family: "Mundial" !important;
        background-color: #FFC035 !important;
        color: #FFFFFF !important;
        font-size: 12px !important;
        border-radius: 5rem !important;
        padding: 0 3rem !important;
        line-height: 4.6rem !important;
        text-transform: uppercase;
        border: 1px solid #FFC035 !important;
        font-weight: 700 !important;
    }
    .entry-summary {
        .price {
            margin-bottom: 24px;
            @include sm {
                margin-bottom: 20px;
            }
            del {
                margin-right: 20px;
            }
            ins {
                .woocommerce-Price-amount {
                    font-weight: 600 !important;
                    color: #C31947 !important;
                }
                text-decoration: none;
            }
           .woocommerce-Price-amount {
            font-size: 24px;
           }
        }
    }

    .product_title.entry-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 25px;
        font-family: "EB Garamond", serif;
    }
    .woocommerce-product-gallery {
        
        @media screen and (min-width: 768px) {
            display: flex;
        }
        .flex-viewport {
            @media screen and (min-width: 768px) {
                order: 2;
                flex: 4;
            }

        }
        .flex-control-thumbs {
            @include md {
                display: flex;
                margin-top: 15px !important;
                li {
                    margin-right: 8px !important;
                }
            }

            @media screen and (min-width: 768px) {
                order: 1;
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-right: 15px !important;
                li {
                    width: 100% !important;
                    
                    margin-bottom: 15px !important;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

        }
    }
    .pswp__bg {
        background: #fffffff2 !important;
    }
    .related.products {
        >h2 {
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 30px;
            font-family: "EB Garamond", serif;
        }
        .add_to_cart_button {
            display: none !important;
        }
    }
}
.wc-block-components-product-name {
    font-family: "EB Garamond", serif;
}
.wc-block-components-totals-item__description, .wc-block-components-totals-shipping__fieldset {
    display: none !important;
}