.product_meta {
}
.shop-menu-container {
    display: flex;
    align-items: center;
    width: 100%;
}
.shop__menu {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0 0 0 15%;
    width: 100%;
    justify-content: center;
    @include md {
        padding: 6rem 0 3rem;
        display: block;
    }
    li {
        margin-right: 5%;
        position: relative;
        @include md {
            margin-right: 0px;
        }
        .sub-menu {
            background-color: rgba(255, 255, 255, 0.4);
            position: absolute;
            padding: 6px 15px;
            left: -15px;
            list-style: none;
            display: none;
            @include md {
                position: relative;
                display: block;
                left: auto;
                background-color: transparent;
            }
            a {
                font-size: 12px;
                padding: 4px;
            }
        }
        &:hover .sub-menu {
            display: block;
        }
        &.is-active a {
            font-weight: 600;
        }
    }
    a {
        font-size: 14px;
        color: #4F4E4C;
        font-weight: 300;
        text-transform: uppercase;
        display: block;
        height: 100%;
        @include md {
            padding: 2rem 0rem;
            display: block;
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: 200;
        }
        &:hover {
            font-weight: 500;
        }
    }
    &--right {
        display: flex;
        align-items: center;
        a {
            margin-right: 16px;
            &:last-child {
                margin-right: 0px;
            }
        }
        .cart {
            position: relative;
            .items {
                background: #FFC035;
                min-width: 16px;
                width: 16px;
                height: 16px;
                position: absolute;
                right: 0;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: white;
                border-radius: 100%;
            }
        }
    }
}
.shop-baner {
    margin-bottom: 40px;
    .title {
        margin-bottom: 25px;
    }
}
.product {
    .woocommerce-loop-product__title {
        font-size: 16px;
        color: #4F4E4C;
        font-weight: normal;
        line-height: 1;
        padding-bottom: 10px;
    }
    .price {
        font-size: 16px;
        color: #da9600;
        font-weight: normal;
        .woocommerce-Price-amount {
            font-size: 16px;
            color: #da9600;

        }
    }
}
.shop-listing {
    margin-top: 60px;
    .shop-listing-wrapper {
        display: grid;
        grid-gap: 50px 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @include lg {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include md {
            grid-template-columns: 1fr 1fr;
        }
        @include sm {
            
        }

    }
    &__item {
        &__title {
            font-size: 16px;
            font-weight: normal;
            color: #4F4E4C;
            line-height: 18px;
            margin: 30px 0px 10px;
        }
        &__price {
            color: #da9600;
            font-size: 16px;
            font-weight: normal;
            &--sale {
                color: #C31947;
            }
            &.on-sale {
                margin-right: 18px;
                text-decoration: line-through;
            }
        }
        .price-wrapper {
            display: flex;
        }
    }
}

.shop-filters {
    
        display: flex;
        justify-content: space-between;
        @include md {
            flex-direction: column;
        }
        >div {
            display: flex;
        }
   
}
.pre-filter {
    font-size: 14px;
    margin-right: 20px;
    line-height: 1;
    &--filter {
        display: none;
        @include md {
            display: inline-block;
        }
    }
}
.sort-wrapper {
    display: flex;
    align-items: baseline;
    @include md {
        width: 100%;
        margin-top: 25px;
    }
}
.filter {
    margin-right: 40px;
    min-width: 250px;
    position: relative;
    @include md {
        min-width: unset;
        width: 100%;
    }
    &--placeholder {
        display: flex;
        align-items: center;
    }
    &:last-child {
        margin-right: 0px;
    }
    &__arrow {
        transition: all .45s ease;
    }
    .filter--current {
        position: relative;
        padding-bottom: 13px;
        border-bottom: 1px solid #4F4E4C;    
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        cursor: pointer;
        &.active {
            .filter__arrow {
                transform: rotate(180deg);
            }
        }
    }
    &__title {
        font-size: 14px;
        font-weight: 400;
    }
    &-list-wrapper {
        display: none;;
        &.list-visible {
            display: block;
        }
    }
    &__list {
        padding: 30px 16px;
        border: 1px solid #FFC035;
        padding: 30px 16px;
        border: 1px solid #FFC035;
        position: absolute;
        z-index: 33;
        left: 0;
        top: 95%;
        width: 100%;
        background: #F6F5F0;
        list-style: none;
        font-size: 14px;
  
        input[type=checkbox] {
            opacity: 0;
            visibility: hidden;
            width: 1px;
            height: 1px;
            position: absolute;
            left: -99999px;
        }

        &--size {
            display: grid;
            grid-gap: 13px 20px;
            grid-template-columns: 1fr 1fr 1fr;
            @include md {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            .form__checkbox {
                border: 1px solid #FFC035;
                padding: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFC035;
                font-size: 14px;
                font-weight: 400;
                border-radius: 100%;
                width: 36px;
                height: 36px;
                line-height: 1;
                cursor: pointer;
                transition: 0.6s;
                position: relative;
                .state {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    z-index: 0;
                }
                &:hover {
                    background-color: #FFC035;
                    color: #F6F5F0;
                }

                input:checked+.state {
                    background-color: #FFC035 !important;
                }
                input:checked+.state+.label {
                    z-index: 33;
                    color: #FFFFFF !important;
                }
            }
        }

        &--sort {
            li {
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.cart-wrapper {
    display: flex;
}
.cart-collaterals {
    width: 25% !important;
    .coupon__box {
        margin-top: 30px;
        .woocommerce-cart-form {
            width: 100% !important;
            margin-right: 0% !important;
        }
        &__title {
            font-size: 12px;
            margin-bottom: 10px;
        }
        .coupon {
            position: relative;
            input {
                width: 100%;
                &::placeholder {
                    font-family: "Mundial" !important;
                    font-weight: 200;
                }
            }
            .button {
                position: absolute;
                right: 0;
                font-size: 12px;
                font-weight: bold;
                text-decoration: underline;
                text-transform: uppercase;
                color: #FFC035;
                top: 0;
                bottom: 0;
                margin: auto 0;
                background-color: #F6F5F0;
                height: fit-content;
            }
        }

    }
    .cart_totals {
        padding: 30px 20px 40px 20px;
        width: 100% !important;
        border-radius: 10px;
        background-color: #fff7e6;
        float: none !important;
        .woocommerce-shipping-totals.shipping {
            display: none !important;
        }
        h2 {
            display: none;
        }
        .shop_table {
            border: none;
        }
        .wc-proceed-to-checkout {
            padding-bottom: 0px;
            text-align: center;
            a {
                margin-bottom: 0px;
                line-height: 4.6rem !important;
                color: #FFC035 !important;
                font-weight: 700 !important;
                font-size: 1.2rem !important;
                border-radius: 5rem !important;
                padding: 0 3rem !important;
                border: 1px solid #FFC035 !important;
                display: inline-block;
                text-transform: uppercase !important;
                background-color: #FFC035 !important;
                color: #fff !important;
            }
        }
    }
}
.woocommerce-cart-form {
    width: 72% !important;
    margin-right: 3%;
    .actions {
       // visibility: hidden;
    }
    thead {
        display: none;
    }
    .shop_table {
        border: none !important;
        td {
            border-top: none !important;
        }
        .cart_item {
            td {
                border-bottom: 1px solid #4F4E4C;
            }
        }
    }
    .cart_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #4F4E4C;
        padding: 35px 0px;
        >div {
            display: flex;
            align-items: center;
        }
        .remove {
            svg path {
                transition: 0.4s;
            }
            &:hover {
                background: none;
                svg path {
                    fill: #C31947;
                }
            }
        }
        .product-quantity {
            input {
                border: 1px solid #4F4E4C;
                padding: 4px;
                margin-right: 30px;
            }
            
        }
        .product-price {
            font-size: 24px;
            margin-right: 34px;
        }
        .left {
            img {
                width: 60px;
                height: auto;
                margin-right: 34px;
            }
            .product-name {
                font-size: 24px;
                font-family: "EB Garamond", serif;
            }
        }
    }
}


.woocommerce-checkout {
    .woocommerce-additional-fields__field-wrapper {
        textarea::placeholder {
            display: none !important;
        }
    }
    .woocommerce-checkout-payment {
        background: none !important;
        ul {
            padding: 0px !important;
            border-bottom: none !important;
        }
    }
    .woocommerce-shipping-methods {
        li {
            display: flex;
            align-items: center;
            margin-bottom: 22px;
            input {
                margin: 0px !important;
                margin-right: 30px !important;
            }
        }
    }
    .checkout {
        .place-order {
            #place_order {
                margin-bottom: 0px;
                line-height: 4.6rem;
                color: #FFC035;
                font-weight: 700;
                font-size: 1.2rem;
                border-radius: 5rem;
                padding: 0 3rem;
                border: 1px solid #FFC035;
                display: inline-block;
                text-transform: uppercase;
                background-color: #FFC035;
                color: #fff;
                width: 100%;
                margin-top: 30px;
            }
        }
        &__box {
            margin-bottom: 40px;
            @include lg {
                margin-bottom: 20px;
            }
        }
        &__final-box {
            background: #fff7e6;
            border-radius: 10px;
            padding: 30px 30px 40px 30px;
            height: 100%;
            position: sticky;
            top: 15px;
            &__product {
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid hsla(0,0%,7%,.11);
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0px;
                }
                &__title {
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "EB Garamond", serif;
                    line-height: 1;
                }
                figure {
                    margin-right: 20px;
                    margin-bottom: 0px;
                    img {
                        width: 60px;
                        height: auto;
                    }
                }

                &--left {
                    display: flex;
                    .meta {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .pieces {
                            line-height: 1;
                            font-size: 12px;
                            color: #da9600;
                        }
                    }
                }
                &--right {
                    display: flex;
                    align-items: flex-end;
                }
            }
            .cisza__summary {
                padding-top: 20px;
                table {
                    width: 100%;
                    label {
                        font-weight: 500;
                    }
                }
            }
        }
        #order_review {
            display: none;
        }
    }
    h3 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 24px;
        line-height: 1;
    }
    #ship-to-different-address {
        font-size: 18px;
    }
    .woocommerce-form-coupon-toggle {
        display: none;
    }
    .checkout-wrapper {
        display: flex;
        @include lg {
            flex-direction: column;
        }
        #customer_details {
            width: 58%;
            margin-right: 5%;
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            @include lg {
                width: 100%;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        .woocommerce-checkout-review-order {
            width: 33%;
            @include lg {
                width: 100%;
            }
        }
    }
    .col-1, .col-2 {
        width: 100% !important;
    }
    #billing_country_field {
        display: none;
    }
    #billing_address_1_field {
        margin-bottom: 10px !important;
    }


}

.woocommerce-account {
    #customer_login h2 {
        font-size: 32px;
        font-family: "EB Garamond", serif;
    }
}


.form-row {
    margin-bottom: 22px !important;
    label {
        line-height: 1.1 !important;
    }
    input {
        padding: 8px 5px;
        &::placeholder {
            font-size: 12px;
            font-family: "Mundial" !important;
            font-weight: 300;
        }
    }
}
.btn-holder {
    text-align: center;
}
.woocommerce-Button, .woocommerce-button {
    margin-bottom: 0px;
    line-height:1 !important;
    color: #FFC035;
    font-weight: 700 !important;
    border-radius: 5rem !important;
    padding: 0 3rem;
    border: 1px solid #FFC035 !important;
    display: inline-block;
    text-transform: uppercase;
    background-color: #FFC035 !important;
    color: #fff !important;
    font-size: 12px !important;
    padding: 17px 54px !important;
}
.woocommerce-form-login__rememberme {
    display: block !important;
    margin-bottom: 14px;
}
.wc-forward, .wc-backward {
    line-height: 4.6rem !important;
    color: #FFC035 !important;
    font-weight: 700 !important;
    font-size: 1.2rem !important;
    border-radius: 5rem !important;
    padding: 0 3rem !important;
    border: 1px solid #FFC035 !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    background: none !important;
}
.woocommerce-message {
    border-color: #FFC035 !important;
}


.wp-block-woocommerce-cart-totals-block, .wp-block-woocommerce-checkout-order-summary-block {
    padding: 30px 16px !important;
    background-color: #fff7e6 !important;
    border-radius: 10px;
}
.wp-block-woocommerce-checkout-order-summary-block {
    @media screen and (min-width:740px) {
        position: sticky;
        top: 30px;
    }
}
.wc-block-components-order-summary .wc-block-components-order-summary-item__quantity {
    border: none !important;
    font-size: 12px !important;
    color: #4F4E4C !important;
    background-color: #F6F5F0 !important;
    box-shadow: none !important;
}
.wc-block-components-radio-control .wc-block-components-radio-control__input {
    border-color: #FFC03594 !important;
}
.wc-block-components-radio-control .wc-block-components-radio-control__input:checked:before {
    background: #FFC035 !important;
}

.wc-block-cart-items__header-image {
    span {
        
    }
}

.product_meta {
    display: none !important;
}

.wc-block-cart__submit-button, .wc-block-components-checkout-place-order-button {
    margin-bottom: 0px;
    line-height:1 !important;
    color: #FFC035;
    font-weight: 700 !important;
    border-radius: 5rem !important;
    padding: 0 3rem;
    border: 1px solid #FFC035 !important;
    display: inline-block;
    text-transform: uppercase;
    background-color: #FFC035 !important;
    color: #fff !important;
    font-size: 12px !important;
    padding: 17px 54px !important;
}

.woocommerce-MyAccount-navigation-link--downloads {
    display: none;
}
.woocommerce-MyAccount-navigation {
    border-radius: 10px;
    background-color: #FAF9F6;
    padding: 8px 20px;
    ul {
        list-style: none;
        li {
            a {
                padding: 12px 0px;
                font-size: 14px;
                font-weight: 500;
            }
            &.is-active {
                a {
                    font-weight: 700;
                }
            }
        }
    }
}
.woocommerce-privacy-policy-link {
    text-decoration: underline;
    margin-bottom: 10px;
    display: inline-block;
}