@font-face {
    font-family: Mundial;
    src: url(../fonts/MundialThin.woff);
    font-weight: 200;
}
@font-face {
    font-family: Mundial;
    src: url(../fonts/MundialRegular.woff);
    font-weight: 400;
}
@font-face {
    font-family: Mundial;
    src: url(../fonts/MundialDemibold.woff);
    font-weight: 600;
}
@font-face {
    font-family: Mundial;
    src: url(../fonts/MundialBold.woff);
    font-weight: 700;
}
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@600&display=swap');

$color-main: #4F4E4C;
$color-green: #FFC035;
$color-greengrey: #ffc64d;
$color-lightgreen: #fff7e6;
$color-lightbg: #F6F5F0;
$color-brown: #856A59;
$color-lightpink: #EFE3E3;

$screen-sm-max: 576px;
$screen-md-max: 768px;
$screen-lg-max: 992px;
$screen-xl-max: 1200px;

@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}
@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}
@mixin rwd($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}



* {
    padding: 0;
    margin: 0;
}
*, *::before, *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
    @include lg {
        font-size: 56%;
    }
}
body {
    font-family: "Mundial";
    font-weight: 200;
    line-height: 1.6;
    color: $color-main;
    min-height: 100vh;
    background: $color-lightbg;
    font-size: 1.6rem;
    overflow-x: hidden;
    &.body-no-scroll {
        height: 100vh;
        overflow-y: hidden;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
    }
}
img {
    display: block;
    width: 100%;
    height: auto;
}
a {
    text-decoration: none;
    color: $color-main;
}

.input, input {
    border-bottom: 1px solid $color-main;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 1.4rem;
    color: $color-main;
    line-height: 4.6rem;
    background-color: transparent;
    &:hover, &:focus {
        border: 0;
        border-bottom: 1px solid $color-main;
        outline: 0;
        box-shadow: none!important;
    }
}
textarea, .textarea {
    @extend .input;
    line-height: 2.4rem;
    height: 11rem;
    font-family: "Mundial";
    margin: 3rem 0 2rem 0;
}

.container {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding: 0 2rem;
    @include md {
        padding: 0 2.2rem;
    }
    &--small {
        @extend .container;
        max-width: 78rem;
        width: 90%;
        @include md {
            width: 100%;
        }
    }
}

.btn {
    line-height: 4.6rem;
    color: $color-green;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 5rem;
    padding: 0 3rem;
    border: 1px solid $color-green !important;
    display: inline-block;
    text-transform: uppercase;
    transition: .4s;
    cursor: pointer;
    &:hover {
        color: white;
        background: $color-green;
    }
    &--white {
        @extend .btn;
        color: #fff;
        border-color: #fff;
    }
    &--green {
        @extend .btn;
        background-color: $color-green;
        color: #fff;

    }
}
.btn-center {
    display: block;
    text-align: center;
    width: 100%;
}
.link-simple {
    color: $color-green;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
}

.title {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
    color: $color-greengrey;
    span {
        display: block;
        width: 100%;
    }
    strong {
        font-size: 5rem;
        line-height: normal;
        font-weight: 600;
        display: inline-block;
        text-transform: none;
        position: relative;
        font-family: 'EB Garamond', serif;
        color: $color-main;
    }
    &--center {
        @extend .title;
        text-align: center;
    }
    &--footer {
        @extend .title;
        strong {
            font-size: 3rem;
            &::before {
                top: 2rem !important;
            }
        }
    }
}
.line {
    strong {
        position: relative;
        &::before {
            position: absolute;
            width: 2000px;
            height: 1px;
            background-color: $color-greengrey;
            top: 3rem;
            content: "";
            display: block;
        }
    }
    &--left {
        @extend .line;
        strong {
            &::before {
                left: -2034px;
            }
        }
    }
    &--right {
        @extend .line;
        strong {
            &::before {
                right: -2034px;
            }
        }
    }
}

.text {
    font-size: 14px;
    font-weight: 200;
    line-height: 24px;
}

.badge {
    line-height: 2.2rem;
    padding: 0 2.4rem;
    font-weight: 700;
    font-size: 1.2rem;
    background-color: $color-lightgreen;
    color: $color-green;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 10%;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.before-box {
    content: "";
    display: block;
    width: 2000px;
    right: 80%;
    height: 18rem;
    background-color: $color-lightgreen;
    position: absolute;
    z-index: 1;
}

.header {
    padding: 3rem;
    position: relative;
    width: 100%;
    z-index: 999;
    @include md {
        display: none;
    }
    &--mobile {
        @extend .header;
        padding: 2.2rem 2.2rem 4rem 2.2rem;
        display: none;
        @include md {
            display: block;
        }
        .container {
            justify-content: space-between;
            z-index: 999;
            background-color: transparent;
        }
    }
    .container {
        max-width: 1220px;
        background-color: rgba(#fff, .4);
        padding: 2rem 4rem;
        display: flex;
        align-items: center;
    }
    &__logo {
        height: 7rem;
        width: auto;
        img {
            height: 100%;
            width: auto;
        }
    }
    &__menu {
        flex: 1;
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 15%;
        @include md {
            padding: 6rem 0 3rem;
            display: block;
        }
        &__item {
            display: flex;
            align-items: center;
            @include md {
                justify-content: baseline;
                display: block;
            }
            a {
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 200;
                display: flex;
                align-items: center;
                @include md {
                    padding: 2rem 0;
                }
            }
            &--contact {
                @extend .header__menu__item;
                a {
                    @extend .btn--green;
                    font-weight: 700;
                    font-size: 1.2rem;
                    @include md {
                        padding: 0 3rem;
                    }
                }
            }
        }
    }
    &-mobile {
        &__cont {
            display: block;
            height: 0;
            background-color: $color-lightbg;
            overflow-y: scroll;
            width: 100%;
            opacity: 0;
            .mobile-shop-menu {
                display: flex;
                flex-direction: column;
                padding-top: 3rem;
                border-top: 1px solid #dcdcdc;
                a {
                    display: flex;
                    align-items: center;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    font-weight: 200;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    span {
                        margin-left: 12px;
                    }
                }
            }
            .container {
                flex-direction: column;
                align-items: normal;
            }
            &.active {
                opacity: 1;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 100;
                padding: 17% 0 0 0;
                .container {
                    position: relative;
                    background-color: transparent;
                }
            }
        }
    }
}
body.page-template-home .header {
    position: absolute;
    @include md {
        position: relative;
    }
}
.open-menu {
    width: 60px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg) scale(0.5);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    border: 0;
    background: transparent;
    float: right;
}
.open-menu span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.open-menu span:nth-child(1) {
    top: 0px;
}
.open-menu span:nth-child(2),.open-menu span:nth-child(3) {
    top: 18px;
}
.open-menu span:nth-child(4) {
    top: 36px;
}
.open-menu.active span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}
.open-menu.active span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.open-menu.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.open-menu.active span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.main-banner {
    position: relative;
    background-color: $color-lightbg;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 90vh;
    width: 100%;
    @include md {
        background-size: 100% auto;
        background-position: right bottom;
        height: 50vh;
    }
    &__content {
        @extend .container;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include md {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
        }
        &__text {
            @extend .text;
            width: 40%;
            padding: 5rem 0 4rem 0;
            @include md {
                width: 100%;
                padding: 4rem 0;
            }
        }
    }
}

.home2 {
    background-color: #fff;
    position: relative;
    .container {
        display: flex;
        align-items: center;
        @include md {
            display: block;
        }
    }
    &__photos {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 4% 0 0;
        @include md {
            width: 100%;
            padding: 0;
        }
        &__img1 {
            width: 70%;
            z-index: 2;
            margin: -4rem 0 0 0;
        }
        &__img2 {
            padding: 3.5rem;
            border: 1px solid $color-greengrey;
            width: 70%;
            margin: -5rem 0 0 30%;
            z-index: 3;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    &__content {
        flex: 1;
        padding: 0 0 7rem 0;
        @include md {
            padding: 6rem 2.2rem 7rem 2.2rem;
            margin: 0 -2.2rem;
            width: 100vw;
            overflow: hidden;
        }
        &__text {
            @extend .text;
            padding: 5rem 0;
        }
    }
    &::before {
        content: "";
        display: block;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 7rem;
        background-color: $color-lightbg;
        position: absolute;
        @include md {
            display: none;
        }
    }
}

.wybierz-zajecia {
    padding: 10rem 0;
    @include md {
        padding: 7rem 0;
    }
    &__cont {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 1.6rem;
        grid-row-gap: 1.6rem;
        padding: 5rem 0 4rem 0;
        z-index: 2;
        position: relative;
        @include md {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include sm {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &__item {
            img {
                width: 100%;
                height: auto;
            }
            &__title {
                font-size: 1.6rem;
                display: block;
                width: 100%;
                padding: 3rem 0 3rem 4rem;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    height: 2rem;
                    width: 2rem;
                    background-color: $color-brown;
                    border-radius: 4rem;
                    position: absolute;
                    left: 0;
                    top: calc(50% - 1rem);
                }
            }
            &__link {
                color: $color-green;
                font-size: 1.2rem;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: underline;
                display: block;
                text-align: center;
                opacity: 0;
            }
            &:hover &__link {
                opacity: 1;
            }
        }
    }
    .container {
        position: relative;
        &::before {
            @extend .before-box;
            @include md {
                display: none;
            }
        }
    }
    &--less-padding {
        @extend .wybierz-zajecia;
        padding-bottom: 0;
    }
}

.blog-news {
    background-color: $color-greengrey;
    padding: 10rem 0;
    @include md {
        padding: 7rem 0;
    }
    &__cont {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 1.6rem;
        grid-row-gap: 1.6rem;
        z-index: 2;
        position: relative;
        padding-bottom: 4.8rem;
        @include md {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &__item {
            padding-right: 15%;
            color: #fff;
            &__date {
                font-size: 1.4rem;
                padding-top: 5rem;
                span {
                    display: block;
                    font-size: 3.2rem;
                    line-height: 3rem;
                }
            }
            &__title {
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.2rem;
                padding: 2.6rem 0 3.2rem 0;
            }
            &__text {
                font-size: 1.6rem;
                line-height: 2.4rem;
                &--big {
                    @extend .blog-news__cont__item__text;
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    padding-top: 4rem;
                }
            }
            &__link {
                display: block;
                font-weight: 700;
                font-size: 1.2rem;
                text-decoration: underline;
                text-transform: uppercase;
                margin-top: 3.2rem;
                color: #fff;
            }
        }
    }
    .line--left strong::before {
        background-color: $color-lightpink;
    }
    .title, .title strong, .text {
        color: #fff;
    }
}

.bestsellers {
    padding: 10rem 0 0 0;
    @include md {
        padding: 7rem 0;
    }
    .container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 7rem;
        @include md {
            display: block;
        }
    }
    .title {
        padding-bottom: 5rem;
    }
    &__info {
        &__text {
            font-size: 1.4rem;
            line-height: 2.4rem;
            @include md {
                padding-bottom: 3rem;
            }
        }
        &__cats {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: 1.5rem;
            padding: 2.8rem 0 5rem 0;
            @include md {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding-bottom: 1rem;
            }
            &__item {
                @include md {
                    width: 60%;
                    margin-bottom: 2rem;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                span {
                    font-weight: 700;
                    text-transform: uppercase;
                    display: block;
                    text-align: center;
                    padding: 1.7rem 0;
                    font-size: 1.2rem;
                    color: $color-greengrey;
                }
                &:nth-child(2) {
                    margin-top: 10rem;
                    @include md {
                        margin: 0;
                    }
                }
            }
        }
    }
    &__products {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 1.6rem;
        grid-row-gap: 6rem;
        padding-bottom: 6rem;
        @include sm {
            
        }
        &__item {
            position: relative;
            &__img {
                background-color: #fff;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 0 3rem 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            &__manuf {
                font-size: 1.2rem;
                line-height: 1.2rem;
                text-transform: uppercase;
                display: none;
            }
            &__title {
                font-size: 1.6rem;
                font-weight: 400;
                padding: 1rem 0 1.2rem 0;
                line-height: 2rem;
            }
            &__price {
                font-size: 1.6rem;
                font-weight: 400;
            }
            &__arrow {
                position: absolute;
                right: 8%;
                bottom: 0;
                width: 4.2rem;
                height: 4.2rem;
                border-radius: 10rem;
                background-color: $color-green;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.courses {
    background-color: #fff;
    padding: 10rem 0;
    @include md {
        padding: 7rem 0;
    }
    .container {
        &::before {
            @extend .before-box;
            height: 28rem;
            @include md {
                display: none;
            }
        }
    }
    &__text {
        @extend .text;
        text-align: center;
        padding: 3rem 20% 4rem 20%;
    }
}

.videos {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 1.6rem;
    grid-row-gap: 5rem;
    position: relative;
    z-index: 2;
    &__item {
        &__img {
            width: 100%;
            height: auto;
            margin: 0 0 3rem 0;
        }
        &__cat {
            font-size: 1.2rem;
            font-weight: 200;
            text-transform: uppercase;
        }
        &__title {
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;
            display: block;
            padding: 0.7rem 0 2rem 0;
        }
        &__price {
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;
            display: block;
            padding: 0 0 2.5rem 0;
        }
        &__link {
            display: inline-block;
            font-size: 1.2rem;
            font-weight: 700;
            color: $color-greengrey;
            text-decoration: underline;
        }
    }
}

.footer {
    padding: 10rem 0 0 0;
    @include md {
        padding: 7rem 0 0 0;
    }
    &__cont {
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding: 5rem 0 0 0;
        margin: 3rem 0 0 0;
        @include md {
            padding: 2rem 0 0 0;
        }
        @include md {
            display: block;
            margin: 0;
        }
        &__box {
            width: 40%;
            background-color: #fff;
            position: relative;
            z-index: 1;
            padding: 6rem 5% 11rem 5%;
            @include md {
                width: 100%;
                padding: 5rem 3rem;
            }
            &__logo {
                @include md {
                    text-align: right;
                }
                img {
                    width: 20%;
                    height: auto;
                    margin: 0 auto;
                    @include md {
                        margin: 0;
                        width: 25%;
                        display: inline-block;
                    }
                }
            }
            &__address {
                font-size: 1.8rem;
                font-weight: 200;
                line-height: 2.1rem;
                padding: 8rem 0 4rem 0;
                @include md {
                    padding-top: 5rem;
                }
                &__strong {
                    display: block;
                    font-size: 2.4rem;
                    line-height: 3.4rem;
                    font-family: 'EB Garamond', serif;
                    padding-bottom: 1rem;
                }
            }
            &__item {
                display: flex;
                align-items: center;
                font-size: 1.8rem;
                margin-bottom: 1.8rem;
                span {
                    display: flex;
                    width: 3.8rem;
                    height: 3.8rem;
                    background-color: $color-lightgreen;
                    border-radius: 4rem;
                    justify-content: center;
                    align-items: center;
                    margin: 0 3rem 0 0;
                    svg, img {
                        max-width: 1.9rem;
                        max-height: 2rem;
                    }
                }
                &--social {
                    @extend .footer__cont__box__item;
                    display: inline-block
                }
            }
            &__title {
                @extend .footer__cont__box__address__strong;
                padding: 1.8rem 0 2.1rem 0;
            }
        }
        &__bg {
            position: absolute;
            width: 70%;
            left: 0;
            top: 0;
            height: auto;
            @include md {
                position: relative;
                width: 100%;
            }
        }
        &__newsletter {
            position: absolute;
            bottom: 8%;
            left: 0;
            width: 60%;
            @include md {
                position: relative;
                bottom: auto;
                left: auto;
                width: 100%;
                padding-top: 3rem;
            }
        }
        &__form {
            @extend .footer__cont__newsletter;
            bottom: 0;
            padding-right: 10%;
            @include md {
                padding-right: 0;
                bottom: auto;
            }
        }
        &__map {
            position: absolute;
            width: 70%;
            left: 0;
            top: 0;
            height: 50%;
            @include md {
                position: relative;
                width: 100%;
                top: auto;
                left: auto;
                aspect-ratio: 1/1;
            }
            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &__links {
        padding: 6rem 0;
        display: flex;
        justify-content: flex-end;
        &__item {
            font-size: 1.4rem;
            padding: 0 2rem;
            line-height: 2rem;
            &:last-child {
                border-left: 1px solid $color-main;
                padding-right: 0;
            }
        }
    }
    &--big {
        padding: 0;
    }
}


.newsletter__form {
    display: flex;
    padding-top: 1rem;
    &__input {
        @extend .input;
        width: 50%;
        margin-right: 10%
    }
    &__btn {
        @extend .btn;
    }
}
.newsletter__zgody {
    padding: 10px 30px 0 0;
    label {
        display: block;
        font-size: 10px;
        text-transform: none;
        color: #333;
        input {
            margin-right: 3px;
            transform: translate(0,3px);
        }
    }
}

.page-intro {
    padding: 2rem 0;
    &__text {
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding: 2rem 0;
    }
}

.info-4photos {
    &__imgs {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 5%;
        @include md {
            display: block;
        }
        img {
            @include md {
                margin: 10px 0;
            }
        }
    }
    &__text {
        @extend .text;
        padding: 5rem 0 2.4rem 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.videos-section {
    padding: 2rem 0;
}

.page-divided {
    @extend .page-intro;
    .container {
        display: flex;
        justify-content: space-between;
        @include md {
            display: block;
        }
    }
    &__left {
        width: 40%;
        @include md {
            width: 100%;
        }
        &__cont {
            position: sticky;
            top: 15%;
        }
    }
    &__right{
        width: 52%;
        @include md {
            width: 100%;
        }
    }
}

.classes {
    .form__checkbox {

        transition: all .3s ease-in-out;
        cursor: pointer;
        position: relative;
        input {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          left: -9999px;
        }
        .label {
          position: relative;
          z-index: 33;
     
        }

        .checkbox:checked + .state {
          display: block;
          background: #FFC035;
          border-color: #FFC035;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 4em;
        }
        .checkbox:checked + .state + .label {
          color: white;
        }
      }
    &__cats {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 1rem 0;
        list-style: none;
        @include md {
            padding: 3rem 0;
        }
        .show-all {
            line-height: 1;
            cursor: pointer;
        }
        &__item {
            padding: 0.6rem 2rem;
            font-size: 1.2rem;
            line-height: 2.4rem;
            font-weight: 700;
            border: 1px solid $color-green;
            color: $color-green;
            border-radius: 4em;
            margin: 0 1rem 1.2rem 0;
            text-transform: uppercase;
            &--active {
                @extend .classes__cats__item;
                background-color: $color-green;
                color: #fff;
            }
        }
    }
    &__products {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 1.6rem;
        grid-row-gap: 6rem;
        @include sm {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &__item {
            &__img {
                aspect-ratio: 1/1;
                margin: 0 0 3rem 0;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__title {
                font-size: 2.1rem;
                font-weight: 400;
                line-height: 2.4rem;
                display: block;
                padding: 0 0 1.8rem 0;
                margin: 0;
                position: relative;
                span {
                    position: absolute;
                    right: 0;
                    top: 2px;
                    font-size: 1.4rem;
                    color: $color-green;
                    @include md {
                        display: block;
                        position: relative;
                        right: auto;
                        top: auto;
                    }
                }
            }
            &__stars {
                display: flex;
                align-items: center;
                margin: 0 0 2.5rem 0;
                &__title {
                    font-size: 1.2rem;
                    font-weight: 400;
                    padding: 0 1rem 0 0;
                    line-height: 1.9rem;
                }
                .selected {
                    path {
                        fill: #FFC035;
                    }
                }
                img, svg {
                    margin: 0 0.5rem 0 0;
                }
            }
            &__text {
                @extend .text;
                padding-bottom: 1.6rem;
            }
            &__price {
                font-size: 1.2rem;
                font-weight: 700;
                margin: 0 0 3.4rem 0;
                display: block;
            }
            &__btn {
                display: block;
                padding: 0 0 3rem 0;
            }
        }
    }
}


.blog-list {
    .container {
        display: flex;
        flex-wrap: wrap;
    }
    @include md {
        padding: 3rem 0 0 0;
    }
    &__item {
        width: 32%;
        padding: 0 0 6rem 0;
        @include md {
            width: 48%;
        }
        @include sm {
            width: 100%;
        }
        &:nth-child(1) {
            width: 100%;
            display: flex;
            align-items: center;
            @include sm {
                flex-wrap: wrap;
            }
            .blog-list__item__img {
                width: 48%;
                margin-right: 4%;
                @include sm {
                    width: 100%;
                    margin: 0;
                    padding: 0 0 3rem 0;
                }
                img {
                    margin-bottom: 0;
                }
            }
            .blog-list__item__cont {
                flex: 1;
            }
            .blog-list__item__title {
                font-size: 3.6rem;
                line-height: 3.6rem;
            }
        }
        &:nth-child(3n) {
            margin-left: 2%;
            margin-right: 2%;
        }
        &__img {
            aspect-ratio: 3/2;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-bottom: 4rem;
            }
        }
        &__date {
            display: block;
            font-size: 1.2rem;
            font-weight: 400;
            padding: 0 0 2rem 0;
        }
        &__title {
            font-family: 'EB Garamond', serif;
            font-size: 2.4rem;
            line-height: 3rem;
            display: block;
            padding: 0 0 2.4rem 0;
            margin: 0;
            font-weight: 400;
        }
        &__text {
            font-size: 1.6rem;
            line-height: 2.4rem;
            padding: 0 0 3rem 0;
        }
    }
}

.info-content {
    img {
        width: 100%;
        height: auto;
        margin: 2rem 0;
    }
    p {
        display: block;
        font-size: 1.6rem;
        line-height: 2.4rem;
        padding:  0 0 2.4rem 0;
    }
    h3 {
        font-size: 2.4rem;
        line-height: 3.4rem;
        font-family: 'EB Garamond', serif;
        padding: 2rem 0;
        margin: 0;
    }
    ul {
        list-style: none;
        padding: 0 0 1rem 0;
        li {
            padding: 0;
            margin: 0 0 1rem 0;
            display: flex;
            align-items: center;
            font-weight: 600;
            &::before {
                content: "";
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 4rem;
                background-color: $color-green;
                margin: 0 1.8rem 0 0;
            }
        }
    }
}

blockquote {
    border: 1px solid $color-green;
    border-radius: 1.6rem;
    padding: 5% 8%;
    font-weight: 200;
    background-image: url(../img/quote.svg);
    background-repeat: no-repeat;
    background-position: 4% 24%;
    background-size: 9% auto;
    p {
        padding: 0;
        margin: 0;
    }
}

.contact-msg--success {
    position: absolute;
    left: -100vw;
    width: 100%;
    height: 100%;
    top: 0;
    background: #F6F5F0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
        max-width: 40px;
        path {
            fill: #da9600;
        }
    }
    &.active {
        left: 0;
    }
}
.contact__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1rem;
    .form__input__group{
        &--error {
            input, textarea {
                border-color: rgb(189, 7, 7);
            }
        }
    }
    &__input {
        input {
            @extend .input;
            width: 100%;
        }
        width: 45%;
    }
    &__textarea {
        width: 100%;
        textarea {
            width: 100%;
        }
        
    }
    .btn {
        background: none;
        cursor: pointer;
    }
}

.sign-in {
    min-height: 500px;
}


.form__custom-checkbox {
    position: relative;
    width: 100%;
    padding-left: 35px;
    min-height: 26px;
    cursor: pointer;
    display:block;
    margin-bottom: 18px;
    input[type=checkbox] {
      opacity: 0;
      visibility: hidden;
      width: 1px;
      height: 1px;
      position: absolute;
      left: -99999px;
    }
    input[type=checkbox]:checked+.state {
      border-color: black;
      &:after {
        opacity: 1;
      }
    }
    .state {
      width: 20px;
      height: 20px;
      border: 2.5px solid black;
      border-radius: 3px;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      background-color: #fff;
      &:after {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        transition: all 0.45s ease;
        background-size: auto;
      }
    }
    .label {
      display: inline-block;
      color: black;
    }
  }
  
  .form__radio {

	position: relative;
	width: 100%;
	padding-left: 40px;
	cursor: pointer;

	.state {
		width: 20px;
		height: 20px;
		border: 1px solid #b4b4b4;
		display: block;
		position: absolute;
		border-radius: 50%;
		top: 50%;
		left: 0;
		transform: translateY(-50%);

		&:after {

			position: absolute;
			opacity: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			transition: all 0.45s ease;
			border-radius: 50%;
			border: 6px solid #FFC035;

		}

	}

	.label {

		color: black;
		font-size: 14px;
		line-height: 26px;

	}

	> input[type="radio"] {

		opacity: 0;
		visibility: hidden;
		width: 1px;
		height: 1px;
		position: absolute;
		left: -99999px;

		&:checked + .state {
			
			border-color: #FFC035;

			&:after {
				opacity: 1;
			}

		}

	}

}

.shop-footer {
    &-wrapper {
        padding-top: 25px;
        padding-bottom: 50px;
        border-top: 1px solid #4F4E4C;
    }
    &__title {
        font-weight: bold;
    }
    ul {
        list-style: none;
        margin-top: 30px;
        display: flex;
        align-items: center;
        @include md {
            flex-direction: column;
            align-items: baseline;
            margin-top: 25px;
        }
        li {
            font-size: 14px;
            margin-right: 50px;
            @include md {
                margin-bottom: 20px;
            }
            &:last-child{
                margin-right: 0px;
                @include md {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
fieldset {
    border: none !important;
    legend {
        font-weight: 700;
    }
}
.update-cart.button {
}

.fotki-cennik {
    display: flex;
    justify-content: space-between;
    @include md {
        display: block;
    }
    img {
        @include md {
            margin-bottom: 20px;
        }
    }
}

#billing_address_1::placeholder, #order_comments::placeholder {
    color: #F6F5F0 !important;
}
#billing_address_2_field {
    display: none !important;
}

.woocommerce-shipping-fields {
    margin: 20px 0px;
}
.woocommerce-additional-fields__field-wrapper {
    margin-bottom: 40px;
}
.woocommerce-terms-and-conditions-wrapper {
    display: none !important;
}

#paczkomat_id_wrapper {
    margin: 0 20px;
    border: 1px;
    border-radius: 20px;
    background: #fff;
    padding: 10px;
}
.wc_payment_method.payment_method_przelewy24 img {
    display: none;
}
.form-row.place-order {
    padding-top: 40px !important;
}

.woocommerce-notice {
    display: block;
    padding-bottom: 30px;
}

.mc4wp-form {
    position: relative;
}
.mc4wp-response {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0,120%);
}

.mc4wp-checkbox {
    padding: 0;
    label {
        position: relative;
    }
    span {
        padding: 0 0 0 33px;
        display: block;
    }
}
.woocommerce form .form-row .input-checkbox {
    width: 20px;
    height: 20px;
    border: 2.5px solid #FFC035;
    border-radius: 3px;
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    background-color: #fff;
}

.wc-block-components-totals-coupon-link {
    font-size: 0px !important;
    &::before {
        display: block;
        font-size: 1.6rem;
        content: "Masz kupon rabatowy?";
        position: absolute;
    }
}
label[for="wc-block-components-totals-coupon__input-0"] {
    color: #fff !important;
    &::before {
        display: block;
        content: "Wpisz kod";
        position: absolute;
        color: #333 !important;
    }
}