@import "base/variables";
@import "base/base";
@import "base/effects";

@import "shop/shop";
@import "shop/product";

@import "../node_modules/swiper/swiper-bundle.min.css";
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import "base/checkbox";