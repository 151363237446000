.form__custom-checkbox {
    position: relative;
    width: 100%;
    padding-left: 35px;
    min-height: 26px;
    cursor: pointer;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 18px;
    &.form__input__group--error {
      .state {
        border-color: #ca0008;
      }
    }
    input[type=checkbox] {
      opacity: 0;
      visibility: hidden;
      width: 1px;
      height: 1px;
      position: absolute;
      left: -99999px;
    }
    input[type=checkbox]:checked+.state {
      border-color: #FFC035;
      &:after {
        opacity: 1;
      }
    }
    .state {
      width: 20px;
      height: 20px;
      border: 2.5px solid #FFC035;
      border-radius: 3px;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      background-color: #fff;
      &:after {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        transition: all 0.45s ease;
        background: #FFC035 url('../img/checkbox.svg') center center no-repeat;
        background-size: auto;
      }
    }
    .label {
      display: inline-block;
      color: #4F4E4C;
      font-size: 10px;
      line-height: 1.2;
      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }